import * as Sentry from '@sentry/nuxt'
import { useRuntimeConfig } from 'nuxt/app'
import { useAppStore } from '~/stores/app'
import { useUserStore } from '~/stores/user'

Sentry.init({
    dsn: 'https://f57bf3845e6aeba810c187ec077cabfe@sentry.referus.ru/3',
    // tracePropagationTargets: [/^\/api\//],
    environment: new URL(location.href).hostname,
    // logErrors: false,
    normalizeDepth: 5,
    maxValueLength: 160,
    ignoreErrors: ['AbortError', 'Unauthorized', 'UnauthorizedError', 'IdInUseError', 'DuplicationError', 'AppError'],
    beforeSend: (ev, hint) => {
        ev.release = useAppStore().frontVersion
        ev.user = { id: useUserStore().sid }

        if (useRuntimeConfig().public.IS_DEV === 'Y') {
            console.debug('Error->Sentry', ev)
            // don't send
            return null
        }
        return ev
    },
})
